import React, { useState, useEffect } from "react";
import ServicesNavbar from "../../components/Services/ServicesNavbar";
import PageMain from "../../components/PageMain";
import { Link } from "react-router-dom";
import Form from "../../components/Home/Form";
import Footer from "../../components/Home/Footer";
import Style from "./market.module.scss";
import GetAQuote from "../../components/GetAQuote";
import blogImg1 from "../../images/BlogBanner/BlogBanners/SmoothsailingthroughcustomsclearanceinOmanAguidetoensurecomplianceavoidingpenaltiesandnavigatingpotentialpitfalls.webp";
import blogImg2 from "../../images/BlogBanner/BlogBanners/HowGCCfreetradezonescanhelpbusinessessimplifycustomsclearance.webp";
import blogImg3 from "../../images/BlogBanner/BlogBanners/Thedigitaltransformationofcustomsclearanceandhowtechnologyistransformingtheprocess.webp";
import blogImg4 from "../../images/BlogBanner/BlogBanners/TheimpactofUAEOmantransportationontradeandcommercetodrivingeconomicgrowth.webp";
import blogImg5 from "../../images/BlogBanner/BlogBanners/TheroleofcrossbordertransportationinpromotingtradefacilitationandregionalintegrationinGCC.webp";
import blogImg6 from "../../images/BlogBanner/BlogBanners/ThefutureoutlookforeventandexhibitionlogisticsinGCCincludingthepotentialforgrowthanddevelopment.webp";
import blogImg7 from "../../images/BlogBanner/BlogBanners/TheIntricateWebofAutomotiveLogisticsinGCC.webp";
import blogImg8 from "../../images/BlogBanner/BlogBanners/CrossBorderCustomsClearance.webp";
import blogImg9 from "../../images/BlogBanner/BlogBanners/FreeZoneAdvantageStrategicInsightsintoFreeZoneClearanceDynamicsinGCCIndustries.webp";
import blogImg10 from "../../images/BlogBanner/BlogBanners/OntheMoveMaximizingEfficiencywithSpecialEquipmentinProjectCargo.webp";
import blogImg11 from "../../images/BlogBanner/BlogBanners/SeamlessTransitNavigatingtheCustomsClearanceProcessforTransitCargo.webp";
import blogImg12 from "../../images/BlogBanner/BlogBanners/StrategicAdvisorySecuringDutyExemptionfromMinistryofCommerceandIndustry.webp";
import blogImg13 from "../../images/BlogBanner/BlogBanners/TemporaryMovesLastingImpactUnderstandingTemporaryImportandExportCustomsClearance.webp";
import blogImg14 from "../../images/BlogBanner/BlogBanners/On-BoardCourierClearanceEnsuringSecureandTimelyDeliveryAcrossBorders.webp";
import blogImg15 from "../../images/BlogBanner/BlogBanners/UnderstandingtheRegulatoryLandscapeofHazmatTransport.webp";
import blogImg16 from "../../images/BlogBanner/BlogBanners/EmergencyResponsePlanningPreparingforIncidentsinHazmattransport.webp";
import blogImg17 from "../../images/BlogBanner/BlogBanners/TheRightEquipmentfortheJobBenefitsofSpecialEquipmentHireandRental.webp";
import blogImg18 from "../../images/BlogBanner/BlogBanners/Legalisation.webp";
import blogImg19 from "../../images/BlogBanner/BlogBanners/Transitclearanceprocessforefficientcross-bordershipmentsandcustomscontrol.webp";
import blogImg20 from "../../images/BlogBanner/BlogBanners/WhattheNewUnifiedGCCCustoms.webp";

const MarketUpdates = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  useEffect(() => {
    const currentPageFromStorage = sessionStorage.getItem("currentPage");
    if (currentPageFromStorage) {
      setCurrentPage(parseInt(currentPageFromStorage));
    }
  }, []);

  const handlePageChange = (pageNumber) => {
    sessionStorage.setItem("currentPage", pageNumber);
    setCurrentPage(pageNumber);
  };

  let cards = [
    {
      image: blogImg1,
      title: "Smooth sailing through customs clearance in Oman: A guide to",
      description:
        "When it comes to international trade and business operations, efficient and compliant customs clearance is",
    },
    {
      image: blogImg2,
      title:
        "How GCC free trade zones can help business simplify customs clearance?",
      description:
        "In today’s competitive business environment, operational efficiency and streamlining processes are of",
    },
    {
      image: blogImg3,
      title:
        "The digital transformation of customs clearance and how technology",
      description:
        "Customs clearance is essential to ensure the smooth flow of products across borders in the fast-paced",
    },
    {
      image: blogImg4,
      title:
        "The impact of UAE-Oman transportation on trade and commerce to driving",
      description:
        "In today’s global economy, businesses are always looking for ways to expand their reach and increase",
    },
    {
      image: blogImg5,
      title:
        "The role of cross-border transportation in promoting trade facilitation",
      description:
        "Globalisation has brought immense opportunities for businesses to expand their reach 'beyond",
    },
    {
      image: blogImg6,
      title:
        "The future outlook for event and exhibition logistics in GCC, including the",
      description:
        "Events and exhibitions are an increasingly important part of business and marketing in the GCC region",
    },
    {
      image: blogImg7,
      title: "The Intricate Web of Automotive Logistics in GCC",
      description:
        "Automotive logistics is an essential component of the thriving automotive industry in the Gulf Cooperation Council (GCC) region. Composed of Bahrain, Kuwait, Oman, Qatar, Saudi Arabia, and the United Arab Emirates, the GCC has witnessed remarkable growth in its automotive sector over the past few decades. As a shipping company in Oman, we understand the intricate web of automotive logistics in the GCC and the challenges it presents. ",
    },
    {
      image: blogImg8,
      title: "Cross-Border Customs Clearance: Navigating New Trade Realities",
      description:
        "Cross-border customs clearance is an essential process in international trade",
    },
    {
      image: blogImg9,
      title: "Free Zone Advantage: Strategic Insights into Free Zone...",
      description:
        "In the realm of business, staying ahead requires a keen eye for strategic advantages. For industries in the Gulf Cooperation Council (GCC), one such advantage",
    },
    {
      image: blogImg10,
      title:
        "On the Move: Maximizing Efficiency with Special Equipment in Project Cargo",
      description:
        "In the fast-paced world of logistics and shipping, efficiency is not just a requirement but a necessity. For those in Oman seeking optimal project cargo",
    },
    {
      image: blogImg11,
      title:
        "Seamless Transit: Navigating the Customs Clearance Process for Transit Cargo",
      description:
        "Navigating the intricate web of customs clearance is a crucial aspect of ensuring seamless transit for cargo. In today's globalized world, efficient customs clearance is not just a requirement",
    },
    {
      image: blogImg12,
      title:
        "Strategic Advisory: Securing Duty Exemption from Ministry of Commerce and Industry",
      description:
        "In the dynamic arena of international trade, businesses face the imperative of staying ahead to ensure continual growth and endurance. One strategic avenue often explored by perceptive",
    },
    {
      image: blogImg13,
      title:
        "Temporary Moves, Lasting Impact: Understanding Temporary Import and Export Customs Clearance",
      description:
        "In today's interconnected world, temporary imports and exports play a vital role in facilitating international trade, cultural exchange, and various cross-border activities. Whether it's showcasing a prototype at a global",
    },
    {
      image: blogImg14,
      title:
        "On-Board Courier Clearance: Ensuring Secure and Timely Delivery Across Borders",
      description:
        "In today's fast-paced global economy, the need for secure and timely international deliveries has become more critical than ever. As businesses expand their reach beyond borders, the demand for reliable shipping solutions",
    },
    {
      image: blogImg15,
      title: "Understanding the Regulatory Landscape of Hazmat Transport",
      description:
        "Transporting hazardous cargo involves strict regulations to ensure safety. Understanding these rules is crucial for compliance and efficiency. We at ALSI Global, as a leading",
    },
    {
      image: blogImg16,
      title:
        "Emergency Response Planning: Preparing for Incidents in Hazmat Transport",
      description:
        "Handling hazardous cargo involves significant risks. We are at ALSI Global, as a leading logistics company in Oman and in the GCC",
    },
    {
      image: blogImg17,
      title:
        "The Right Equipment for the Job: Benefits of Special Equipment Hire and Rental",
      description:
        "At ALSI Global, we understand the dynamic nature of today's business landscape. Projects often require a diverse range of specialized equipment, and owning and maintaining",
    },
    {
      image: blogImg18,
      title:
        "Documentation Services in Customs Clearance: Why Legalisation and Attestation Matter",
      description:
        "In the world of global trade, documentation is key to ensuring smooth and compliant movement of goods across borders. For any shipment, especially those involving multiple countries or specialized goods",
    },
    {
      image: blogImg19,
      title:
        "Transit Clearance Explained: What You Need to Know for Cross-Border Shipments ",
      description:
        "When moving goods across multiple countries, one of the essential processes that must be managed carefully is transit clearance. Transit clearance is the procedure through which goods, in transit from one country to another",
    },
    {
      image: blogImg20,
      title:
        "What the New Unified GCC Customs Tariff Means for Businesses in 2025",
      description:
        "The Gulf Cooperation Council (GCC) countries are set to introduce a major update to their customs tariff system starting January 1, 2025. This new development announced by the General Administration of"
    },
  ];

  cards = cards.reverse();

  const indexOfLastCard = currentPage * itemsPerPage;
  const indexOfFirstCard = indexOfLastCard - itemsPerPage;
  const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);

  return (
    <div>
      <ServicesNavbar />
      <PageMain
        main={{
          style: {
            backgroundImage: "url(images/Market/market.webp)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          },
        }}
        introduction={[]}
        title="Market Updates"
      />

      <div className="container container-sm container-md container-lg container-xl container-xxl">
        <div className={`row ${Style["market-u-top-h"]}`}>
          {currentCards.map((card, index) => {
            const totalItems = cards.length;
            const cardIndex = totalItems - (indexOfFirstCard + index);
            return (
              <div className={`col-md-3 mb-4 `} key={index}>
                <div
                  className={`card p-2 h-100 ${Style["market-update-card"]}`}
                >
                  <img
                    src={card.image}
                    className="w-100"
                    style={{
                      height: "200px",
                      borderRadius: "7px",
                      objectFit: "cover",
                    }}
                    alt=""
                    loading="lazy"
                  />
                  <div className="px-2 mt-3 d-flex flex-column">
                    <div className={` ${Style["card-title-wrapper"]}`}>
                      <p className="card-title fw-bold">{card.title}</p>
                      <p className="m-0" style={{ fontSize: "13px" }}>
                        {card.description}...
                      </p>
                    </div>
                    <div className="py-2">
                      <Link
                        to={`/market-updates/${cardIndex}`}
                        style={{
                          color: "rgb(25, 51, 119)",
                        }}
                        className="d-block py-2 text-decoration-none  fw-semibold"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <nav
        aria-label="Page navigation"
        style={{ margin: "-30px 0px 70px 0px" }}
      >
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link pagination-button-blogs"
              onClick={() => handlePageChange(currentPage - 1)}
              style={{
                border: "0px solid white",
                padding: "8px 15px",
                borderRadius: "2px",
                fontWeight: "700",
                fontSize: "14px",
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.3)",
                margin: "0px 5px",
              }}
            >
              Previous
            </button>
          </li>
          {Array.from(
            { length: Math.ceil(cards.length / itemsPerPage) },
            (_, index) => (
              <li
                className={`page-item pagination-button-blogs ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                key={index}
              >
                <button
                  className="page-link pagination-button-blogs"
                  onClick={() => handlePageChange(index + 1)}
                  style={{
                    border: "0px solid white",
                    padding: "8px 15px",
                    borderRadius: "5px",
                    fontWeight: "700",
                    fontSize: "14px",
                    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.3)",
                    margin: "0px 5px",
                  }}
                >
                  {index + 1}
                </button>
              </li>
            )
          )}
          <li
            className={`page-item pagination-button-blogs ${
              currentPage === Math.ceil(cards.length / itemsPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <button
              className="page-link pagination-button-blogs"
              onClick={() => handlePageChange(currentPage + 1)}
              style={{
                border: "0px solid white",
                padding: "8px 15px",
                borderRadius: "5px",
                fontWeight: "700",
                fontSize: "14px",
                boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.3)",
                margin: "0px 5px",
              }}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>

      <Form />
      <Footer />
      <GetAQuote />
    </div>
  );
};

export default MarketUpdates;
